// import useJwt from '@/auth/jwt/useJwt'
import io from 'socket.io-client'
import Echo from 'laravel-echo'

let echo

export default class BroadcastService {
  static async connectSocket() {
    if (echo) {
      return
    }
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)
    const sha1 = await this.sha1(this.userData.email)

    window.io = io
    echo = new Echo({
      broadcaster: 'socket.io',
      host: process.env.VUE_APP_SOCKET_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${sha1}`,
          Accept: 'application/json',
        },
      },
      // authEndpoint: 'custom/endpoint/auth',
    })
  }

  static async listen(event, closure) {
    await this.connectSocket()

    echo.private(`cms.broadcast.user.${this.userData.id}`)
      .listen(`.${event}`, n => {
        closure(n)
      })
  }

  static async sha1(str) {
    const enc = new TextEncoder()
    const hash = await crypto.subtle.digest('SHA-1', enc.encode(str))
    return Array.from(new Uint8Array(hash))
      .map(v => v.toString(16).padStart(2, '0'))
      .join('')
  }
}

import useJwt from '@/auth/jwt/useJwt'

export default class IssueService {
  static list({
    dateFrom,
    dateTo,
    page,
    from_id: fromId,
    to_id: toId,
    user_id: userId,
    status,
    tags,
  }) {
    return useJwt.get('/issues', {
      dateFrom,
      dateTo,
      page: page ?? 1,
      fromId,
      toId,
      userId,
      status,
      tags,
    })
  }

  static store(args) {
    return useJwt.post('/issues/store', {}, args)
  }

  static my() {
    return useJwt.get('/issues/my')
  }

  static comments({ issueId }) {
    return useJwt.get(`/issues/${issueId}/comments`)
  }

  static dismiss(issueId) {
    return useJwt.post(`/issues/${issueId}/dismiss`)
  }

  static getUrlDownloadAttachment({ name, file }) {
    return useJwt.download(`/issues/attachment/${name}/${file}`)
  }
}

import useJwt from '@/auth/jwt/useJwt'

export default class NotificationService {
  static list() {
    return useJwt.get('/notifications')
  }

  static my() {
    return useJwt.get('/notifications/my')
  }

  static dismiss(notificationId) {
    return useJwt.delete(`/notification/${notificationId}`)
  }
}

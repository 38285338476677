<template>
  <div class="issues__nav-dropdown">
    <b-nav-item-dropdown
      ref="issue-dropdown"
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <div class="position-relative">
          <feather-icon
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="21"
          />
          <b-badge
            v-if="issues.filter(f => f.status === 'open').length"
            pill
            variant="danger"
            class="position-absolute notification-badge"
          >
            {{ issues.filter(f => f.status === 'open').length }}
          </b-badge>
        </div>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex align-items-center">
          <h4 class="notification-title mb-0 mr-auto d-flex justify-content-start align-items-center">
            <span>
              Notificações
            </span>

            <div
              v-if="finishedInitialLoad"
              class="d-flex align-items-center ml-1"
            >
              <b-button
                size="sm"
                variant="primary"
                class="time-to-refresh"
                @click.stop="refreshAll"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <feather-icon
                  v-else
                  icon="RotateCwIcon"
                  size="15"
                />
              </b-button>
            </div>
          </h4>

          <b-button
            variant="primary"
            size="sm"
            @click="changeIssueSidebar({
              issue: null,
              open: true
            })"
          >
            Criar
          </b-button>
        </div>
      </li>

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <div
          v-if="!finishedInitialLoad"
          class="d-flex py-5 justify-content-center"
        >
          <b-spinner
            label="Carregando..."
          />
        </div>
        <div
          v-if="finishedInitialLoad && issues.length === 0"
          :class="`d-flex py-5 justify-content-center ${isDarkTheme ? 'text-white' : 'text-black'}`"
        >
          Tudo resolvido 🎉
        </div>
        <div
          v-else
        >
          <b-media
            v-for="(issue, index) in issues"
            :key="issue.id"
            class="cursor-pointer"
            no-body
            @click="changeIssueSidebar({ issue, open: true })"
          >
            <b-media-aside v-if="issue.type && issue.notification">
              <b-avatar-group
                size="
                32px"
                variant="light-success"
                overlap=".1"
              >
                <b-avatar
                  v-b-tooltip.hover="issue.from && issue.from.name ? issue.from.name : 'Notificação'"
                  src=""
                >
                  <feather-icon icon="AlertCircleIcon" />
                </b-avatar>
              </b-avatar-group>
            </b-media-aside>
            <b-media-body v-if="issue.type && issue.notification">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="media-heading font-weight-bolder">
                    <s
                      v-if="issue.read_at"
                    >
                      {{ issue.title }}
                    </s>
                    <span v-else>
                      {{ issue.title }}
                    </span>
                  </p>
                  <small v-if="issue.text">
                    {{ issue.text }}
                  </small>
                  <small
                    v-if="issue.read_at"
                    class="notification-text"
                  >
                    Encerrado {{ moment(issue.read_at).fromNow() }}
                  </small>
                  <small
                    v-else
                    class="notification-text"
                  >
                    <br />
                    Última atualização {{ moment(issue.updated_at).fromNow() }}
                  </small>
                  <small
                    v-if="issue.user_id"
                    class="d-block notification-text"
                  >
                    ID: {{ issue.user_id }}
                    <b-button
                      size="sm"
                      variant="flat-secondary"
                      title="Copiar ID"
                      @click.stop="copyText(issue.user_id)"
                    ><feather-icon
                      icon="CopyIcon"
                    /></b-button>
                    <b-button
                      size="sm"
                      variant="flat-secondary"
                      title="Abrir Usuário"
                      @click.stop="openUser(issue.user_id)"
                    ><feather-icon
                      icon="ExternalLinkIcon"
                    /></b-button>
                  </small>
                </div>

                <div
                  class="ml-1"
                >
                  <div class="d-flex flex-column align-items-center justify-content-end">
                    <b-badge variant="light-secondary">
                      Notificação
                    </b-badge>
                    <br />
                    <b-button
                      v-if="!issue.read_at"
                      variant="success"
                      @click.stop="dismissFinished(issue, index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-media-body>

            <b-media-aside v-if="!issue.type || !issue.notification">
              <b-avatar-group
                size="
                32px"
                variant="light-success"
                overlap=".1"
              >
                <b-avatar
                  v-b-tooltip.hover="issue.from && issue.from.name ? issue.from.name : 'Sem criador'"
                  :src="issue.from && issue.from.avatar ? issue.from.avatar.sm : ''"
                >
                  <b-img
                    v-if="!issue.from"
                    :src="appLogoImage"
                    alt="logo"
                    style="width: 20px"
                  />
                </b-avatar>
                <b-avatar
                  v-if="issue.to_all_from"
                  v-b-tooltip.hover="`Equipe ${$t(`roles.${issue.to_all_from}`)}`"
                  :src="''"
                  variant="light-success"
                >
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                    style="width: 20px"
                  />
                </b-avatar>
                <b-avatar
                  v-else
                  v-b-tooltip.hover="issue.to && issue.to.name ? issue.to.name : `Equipe ${$t('roles.management_processes')}`"
                  :src="issue.to && issue.to.avatar ? issue.to.avatar.sm : ''"
                >
                  <b-img
                    v-if="!issue.to"
                    :src="appLogoImage"
                    alt="logo"
                    style="width: 20px"
                  />
                </b-avatar>
              </b-avatar-group>
            </b-media-aside>
            <b-media-body v-if="!issue.type || !issue.notification">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="media-heading font-weight-bolder">
                    <s
                      v-if="issue.finished_at"
                    >
                      {{ issue.title }}
                    </s>
                    <span v-else>
                      {{ issue.title }}
                    </span>
                  </p>
                  <small v-if="issue.text">
                    {{ issue.text }}
                  </small>
                  <small
                    v-if="issue.finished_at"
                    class="notification-text"
                  >
                    Encerrado {{ moment(issue.finished_at).fromNow() }}
                  </small>
                  <small
                    v-else
                    class="notification-text"
                  >
                    Última atualização {{ moment(issue.updated_at).fromNow() }}
                  </small>
                  <small
                    v-if="issue.user_id"
                    class="d-block notification-text"
                  >
                    ID: {{ issue.user_id }}
                    <b-button
                      size="sm"
                      variant="flat-secondary"
                      title="Copiar ID"
                      @click.stop="copyText(issue.user_id)"
                    ><feather-icon
                      icon="CopyIcon"
                    /></b-button>
                    <b-button
                      size="sm"
                      variant="flat-secondary"
                      title="Abrir Usuário"
                      @click.stop="openUser(issue.user_id)"
                    ><feather-icon
                      icon="ExternalLinkIcon"
                    /></b-button>
                  </small>
                </div>

                <div
                  class="ml-1"
                >
                  <div class="d-flex flex-column align-items-center justify-content-end">
                    <b-badge variant="light-info">
                      Ticket
                    </b-badge>
                    <b-button
                      v-if="issue.finished_at"
                      variant="success"
                      class="ml-1"
                      @click.stop="dismissFinished(issue, index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-media-body>
          </b-media>
        </div>
      </vue-perfect-scrollbar>

      <li class="dropdown-menu-footer">
        <b-button
          variant="primary"
          :to="{ name: 'cms-issues' }"
          target="_blank"
          block
        >
          Abrir todos chamados
        </b-button>
      </li>
    </b-nav-item-dropdown>
    <b-sidebar
      width="430px"
      :no-close-on-backdrop="preventCloseSidebar"
      :no-close-on-esc="preventCloseSidebar"
      no-header
      lazy
      right
      backdrop
      shadow
      :visible="sidebarIssueOpen"
      bg-variant="white"
      @hidden="changeIssueSidebar({ issue: null, open: false })"
    >
      <issue-sidebar
        :issue="sidebarIssue"
        @prevent-close-sidebar="changePreventCloseSidebar"
      />
    </b-sidebar>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BSidebar,
  BNavItemDropdown,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BImg,
  BButton,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed } from '@vue/composition-api'
import IssueService from '@/services/issueService'
import UserService from '@/services/userService'
import { $themeConfig } from '@themeConfig'
import toast from '@/mixins/toast'
import BroadcastService from '@/services/broadcastService'
import NotificationService from '@/services/notificationService'
import IssueSidebar from './Sidebar.vue'

import 'moment/locale/pt-br'

export default {
  components: {
    BSidebar,
    BNavItemDropdown,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BAvatarGroup,
    BBadge,
    BImg,
    BButton,
    BSpinner,
    VuePerfectScrollbar,
    IssueSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [toast],
  data() {
    return {
      sidebarIssue: null,
      sidebarIssueOpen: false,

      preventCloseSidebar: false,
      isLoading: false,
      issues: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },

      finishedInitialLoad: false,
      whenToRefresh: null,

      userService: null,
      managers: [],

      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDarkTheme = computed(() => skin.value === 'dark')

    return { isDarkTheme }
  },

  async mounted() {
    this.userService = new UserService()
    this.managers = await this.userService.getAndStoreManagers()

    this.$root.$on('cms::issue-sidebar', this.changeIssueSidebar)
    this.$root.$on('cms::issue-refresh', this.refreshAll)

    await BroadcastService.listen('newScheduleQualifiedUser', data => {
      this.addNotificationToIssues(data)
    })

    await BroadcastService.listen('newScheduleKanban', data => {
      this.addNotificationToIssues(data)
    })

    await BroadcastService.listen('refreshIssue', () => {
      this.refreshAll()
    })

    this.refreshAll()
  },

  unmounted() {
    this.$root.$off('cms::issue-refresh', this.refreshAll)
    this.$root.$off('cms::issue-sidebar', this.changeIssueSidebar)
  },
  methods: {
    addNotificationToIssues(data) {
      this.issues.unshift({
        id: data.uuid,
        title: data.subject,
        text: data.body,
        user_id: data.user_id,
        created_at: moment().format(),
        updated_at: moment().format(),
        read_at: null,
        status: 'open',
        notification: true,
        type: data.event,
      })
    },

    async copyText(text) {
      await navigator.clipboard.writeText(text)
      this.toastSuccess('Texto copiado!', 'ID do usuário copiado para a área de transferência.')
    },
    openUser(userId) {
      this.$router.push({ name: 'cms-user-form-update', params: { id: userId } })
      this.$refs['issue-dropdown'].hide()
    },
    moment,
    changeIssueSidebar({ issue, open }) {
      if (issue) {
        this.sidebarIssue = issue
      } else {
        this.sidebarIssue = null
      }

      if (this.preventCloseSidebar) {
        return
      }

      this.sidebarIssueOpen = open
    },
    changePreventCloseSidebar(event) {
      this.preventCloseSidebar = event
    },
    refreshAll() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      IssueService.my().then(response => {
        this.issues = response.data.data.issues
      }).finally(async () => {
        this.isLoading = false

        if (!this.finishedInitialLoad) {
          const notifications = await NotificationService.my()

          notifications.data.data.notifications.forEach(item => {
            if (!this.issues.find(f => f.id === item.id)) {
              this.issues.unshift({
                id: item.id,
                title: item.data.subject,
                text: item.data.text,
                user_id: item.data.user_id,
                created_at: item.created_at,
                updated_at: item.updated_at,
                read_at: null,
                status: 'open',
                notification: true,
                type: item.data.type,
              })
            }
          })
        }

        this.sortIssues()

        this.finishedInitialLoad = true
      })
    },

    sortIssues() {
      this.issues.sort((a, b) => {
        const aStatus = a.status === 'open' ? 1 : 0
        const bStatus = b.status === 'open' ? 1 : 0
        if (aStatus < bStatus) return 1
        if (bStatus < aStatus) return -1
        return new Date(b.updated_at)
            - new Date(a.updated_at)
      })
    },

    dismissFinished(issue, index) {
      if (issue.type && issue.notification) {
        const i = issue
        i.status = 'closed'
        i.read_at = moment().format()
        NotificationService.dismiss(issue.id)
        this.sortIssues()
      } else {
        this.issues.splice(index, 1)
        IssueService.dismiss(issue.id)
        this.sortIssues()
      }
    },
  },
}
</script>

<style lang="scss">
.issues__nav-dropdown {
  .notification-badge {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    font-size: 0.75em;
    font-weight: normal;
  }
}
</style>

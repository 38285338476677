<template>
  <div class="issues-sidebar">
    <div class="p-1 sidebar-header">
      <b-button
        variant="flat-success"
        class="btn-icon text-dark"
        @click="$root.$emit('cms::issue-sidebar', { open: false })"
      >
        <feather-icon icon="XIcon" />
      </b-button>
    </div>

    <b-overlay
      :show="isLoading"
      :variant="variant"
    >
      <b-tabs
        class="p-2"
      >
        <b-tab
          v-if="!issue"
        >
          <template #title>
            <span>Novo chamado</span>
          </template>

          <validation-observer ref="rulesNewIssue">
            <validation-provider
              #default="{ errors }"
              rules="required|min:10"
            >
              <b-form-group
                label="Título"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="integer"
            >
              <b-form-group
                label="ID do usuário afetado"
                label-for="user_id"
              >
                <b-form-input
                  id="user_id"
                  v-model="user_id"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="required|min:10"
            >
              <b-form-group
                label="Descrição"
                label-for="content"
              >
                <b-form-textarea
                  id="content"
                  v-model="content"
                  rows="6"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-form-group
              label="Anexo"
              label-for="attachment"
            >
              <b-form-file
                id="attachment"
                v-model="attachment"
                placeholder=""
                drop-placeholder=""
                browse-text="Anexar"
              />
            </b-form-group>
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Tags"
                label-for="tags"
              >
                <v-select
                  id="tags"
                  ref="tags-select"
                  v-model="tags"
                  multiple
                  :options="issueTagOptions"
                  label="text"
                  :reduce="tag => tag.value"
                  @option:selected="$nextTick(() => $refs['tags-select'].searchEl.focus())"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
            >
              <b-form-group
                label="Atribuir a"
                label-for="assign_to"
              >
                <b-form-select
                  id="assign_to"
                  v-model="assignTo"
                  :options="optionsAssignTo"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              variant="primary"
              @click="handleNewIssue"
            >
              Criar
            </b-button>
          </validation-observer>
        </b-tab>
        <b-tab
          v-if="issue"
        >
          <template #title>
            <span>Chamado #{{ issue.id }}</span>
          </template>
          <p class="mt-1">
            <b>Título: {{ issue.title }}</b>
          </p>
          <p>
            <b-badge
              pill
              :variant="{
                closed: 'success',
                open: 'danger',
              }[issue.status]"
            >
              {{ $t('issue.status')[issue.status] }}
            </b-badge>
            <span v-if="issue.status === 'open'">
              por {{ issue.from.name }} às {{ formatDateTimeDbToView(issue.created_at) }}
            </span>
            <span v-if="issue.status === 'closed'">
              por {{ issue.finisher.name }} às {{ formatDateTimeDbToView(issue.finished_at) }}
            </span>
          </p>
          <p>
            <b-badge
              v-for="tag in issue.tags"
              :key="tag"
              pill
              style="margin: 0 5px 5px 0"
            >
              {{ $t('issue.tag')[tag] }}
            </b-badge>
          </p>
          <div
            class="d-flex mb-1"
          >
            <div
              v-if="issue.to || issue.to_all_from"
              class="col-6"
            >
              <p>
                Responsável
              </p>
              <div
                v-if="issue.to_all_from"
                class="d-flex flex-column align-items-center"
              >
                <b-avatar
                  size="40px"
                  :src="''"
                  variant="light-success"
                >
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                    style="width: 20px"
                  />
                </b-avatar>
                <div>
                  <b>Equipe {{ $t(`roles.${issue.to_all_from}`) }}</b>
                </div>
              </div>
              <div
                v-else-if="issue.to"
                class="d-flex flex-column align-items-center"
              >
                <b-avatar
                  size="40px"
                  :src="issue.to.avatar ? issue.to.avatar.sm : ''"
                  variant="light-success"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
                <div>
                  <b>{{ issue.to.name }}</b>
                </div>
              </div>
            </div>
            <div
              v-if="issue.user_id"
              class="col-6"
            >
              <p>
                Usuário afetado
              </p>
              <b-button
                variant="primary"
                :to="{ name: 'cms-user-form-update', params: { id: issue.user_id } }"
                target="_blank"
              >
                {{ issue.user_id }}
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="14"
                />
              </b-button>
            </div>
          </div>
          <b-card
            v-if="issue.status !== 'closed'"
            :bg-variant="variant"
          >
            <div
              v-b-toggle.issue__new_comment
              class="d-flex justify-content-between full-width"
            >
              <b>
                Novo comentário
              </b>
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                class="when-closed"
              />
              <feather-icon
                icon="ChevronUpIcon"
                size="16"
                class="when-open"
              />
            </div>
            <b-collapse
              id="issue__new_comment"
              class="pt-1"
            >
              <validation-observer ref="rulesNewComment">
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:10"
                >
                  <b-form-group
                    label="Comentário"
                    label-for="content"
                  >
                    <b-form-textarea
                      id="content"
                      v-model="content"
                      rows="6"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  label="Anexo"
                  label-for="attachment"
                >
                  <b-form-file
                    id="attachment"
                    v-model="attachment"
                    placeholder=""
                    drop-placeholder=""
                    browse-text="Anexar"
                  />
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Tags"
                    label-for="tags"
                  >
                    <v-select
                      id="tags"
                      ref="tags-select"
                      v-model="tags"
                      multiple
                      :options="issueTagOptions"
                      label="text"
                      :reduce="tag => tag.value"
                      @option:selected="$nextTick(() => $refs['tags-select'].searchEl.focus())"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Atribuir a"
                    label-for="assign_to"
                  >
                    <b-form-select
                      id="assign_to"
                      v-model="assignTo"
                      :options="optionsAssignTo"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <b-form-select
                      id="status"
                      v-model="status"
                      :options="optionsStatus"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <div
                  class="mt-2 d-flex justify-content-end"
                >
                  <b-button
                    variant="gradient-primary"
                    @click="handleNewComment"
                  >
                    Comentar
                  </b-button>
                </div>
              </validation-observer>
            </b-collapse>
          </b-card>
          <div
            v-if="isLoadingComments"
            class="d-flex justify-content-center py-2"
          >
            <b-spinner
              label="Carregando..."
            />
          </div>
          <div v-else>
            <b-card
              v-for="comment in comments"
              :key="comment.id"
              :bg-variant="variant"
              no-body
              class="p-0 mb-1"
            >
              <b-card-body>
                <div class="d-flex align-items-center justify-content-start">
                  <b-avatar
                    size="40px"
                    :src="comment.commenter.avatar ? comment.commenter.avatar.sm : ''"
                    variant="light-success"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                  <div>
                    <div>
                      <b>{{ comment.commenter.name }}</b>
                    </div>
                    <small v-b-tooltip:hover="formatDateTimeDbToView(comment.created_at)">
                      {{ moment(comment.created_at).fromNow() }}
                    </small>
                  </div>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="m-0 mt-1"
                  v-html="nl2br(comment.content)"
                />
                <!-- eslint-enable vue/no-v-html -->
                <b-button
                  v-if="comment.attachment_name"
                  variant="secondary"
                  class="mt-1"
                  :disabled="comment.isDownloading"
                  size="sm"
                  @click="attachmentDownload(comment)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  />
                  Baixar anexo
                  <b-spinner
                    v-if="comment.isDownloading"
                    small
                  />
                </b-button>
              </b-card-body>
            </b-card>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormInput,
  BBadge,
  BAvatar,
  BCollapse,
  BOverlay,
  BFormSelect,
  BFormFile,
  BFormTextarea,
  BFormGroup,
  BButton,
  BCard,
  BCardBody,
  BSpinner,
  BTabs,
  BTab,
  VBToggle,
  VBTooltip, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import UserService from '@/services/userService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IssueService from '@/services/issueService'
import { formatDateTimeDbToView, nl2br } from '@/utils/helpers'
import { managersOptions, issueTagOptions } from '@/utils/options'
import downloadFile from '@/mixins/downloadFile'
import { $themeConfig } from '@themeConfig'
import moment from 'moment'

import 'moment/locale/pt-br'

export default {
  name: 'IssueSidebar',
  components: {
    BImg,
    BFormInput,
    BBadge,
    BAvatar,
    BCollapse,
    BOverlay,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BFormGroup,
    BButton,
    BCard,
    BCardBody,
    BSpinner,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [
    downloadFile,
  ],
  props: {
    issue: {
      type: Object,
      default: null,
    },
  },
  data() {
    const defaultAssignTo = this.issue ? (this.issue.to_all_from || this.issue.to_id || 'management_processes') : 'management_processes'
    const defaultTitle = ''
    const defaultContent = ''
    const defaultUserId = ''
    const defaultAttachment = null
    const defaultTags = []

    return {
      isLoading: false,
      defaultAssignTo,
      assignTo: defaultAssignTo,
      optionsAssignTo: [],
      optionsStatus: Object.keys(this.$t('issue.status')).map(status => ({
        value: status,
        text: this.$t(`issue.status.${status}`),
      })),
      defaultTitle,
      title: defaultTitle,
      defaultContent,
      content: defaultContent,
      defaultAttachment,
      attachment: defaultAttachment,
      defaultTags,
      tags: defaultTags,
      defaultUserId,
      user_id: defaultUserId,
      status: '',

      comments: [],
      isLoadingComments: false,
      userService: null,
      issueTagOptions,

      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
  computed: {
    variant() {
      return document.body.classList.contains('dark-layout') ? 'dark' : undefined
    },
  },
  watch: {
    issue(newIssue, oldIssue) {
      if (!newIssue) {
        return
      }

      if (newIssue !== oldIssue) {
        this.extractDataFromIssue(newIssue)

        this.getComments()
      }
    },
  },
  async mounted() {
    this.isLoading = true

    this.userService = new UserService()

    this.optionsAssignTo = [
      ...managersOptions,
    ]

    this.managers = await this.userService.getAndStoreManagers()

    if (this.issue) {
      this.extractDataFromIssue(this.issue)

      await this.getComments()
    }

    this.isLoading = false
  },
  methods: {
    attachmentDownload(comment) {
      this.comments.find(c => c.id === comment.id).isDownloading = true
      this.comments = [...this.comments]

      return IssueService.getUrlDownloadAttachment({
        name: comment.attachment_name,
        file: comment.attachment_path,
      }).then(response => {
        this.comments.find(c => c.id === comment.id).isDownloading = false
        this.comments = [...this.comments]

        this.openModalToSaveFile(response, comment.attachment_name)
      })
    },
    nl2br,
    formatDateTimeDbToView,
    moment,
    extractDataFromIssue(issue) {
      this.status = issue.status
      this.tags = issue.tags
    },
    toast(title, text, success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: success ? 'EditIcon' : 'BellIcon',
          variant: success ? 'info' : 'danger',
        },
      }, {
        position: 'top-center',
      })
    },

    getComments() {
      if (this.issue.comments) {
        this.comments = this.issue.comments
        return
      }

      this.isLoadingComments = true

      IssueService.comments({ issueId: this.issue.id }).then(response => {
        if (response.status === 200) {
          this.comments = response.data.data.issue_comments
        } else {
          this.toast('Erro!', 'Não foi possível carregar os comentários do chamado', false)
        }
      }).catch(error => {
        const errorMessage = error?.response?.data?.errors[1]

        if (errorMessage) {
          this.toast('Erro!', errorMessage, false)
        } else {
          this.toast('Erro!', 'Não foi possível carregar os comentários do chamado', false)
        }
      }).finally(() => {
        this.isLoadingComments = false
      })
    },

    clearNewIssueForm() {
      this.title = this.defaultTitle
      this.content = this.defaultContent
      this.assignTo = this.defaultAssignTo
      this.user_id = this.defaultUserId
      this.tags = this.defaultTags
      this.attachment = this.defaultAttachment
    },

    handleNewIssue() {
      this.$refs.rulesNewIssue.validate().then(success => {
        if (!success) {
          return
        }

        this.isLoading = true
        this.$emit('prevent-close-sidebar', true)

        /* eslint-disable camelcase */
        const {
          assignTo: to_id,
          user_id,
          attachment,
          title,
          content,
          tags,
        } = this

        const formData = new FormData()
        formData.append('title', title)
        formData.append('content', content)

        tags.forEach(tag => {
          formData.append('tags[]', tag)
        })

        if (Number.isNaN(parseInt(to_id, 10))) {
          formData.append('to_all_from', to_id)
        } else {
          formData.append('to_id', to_id)
        }

        if (user_id) {
          formData.append('user_id', user_id)
        }

        if (attachment) {
          formData.append('attachment', attachment)
        }

        IssueService.store(formData).then(response => {
          if (response.status === 200) {
            const { issue } = response.data.data

            this.clearNewIssueForm()
            this.$root.$emit('cms::issue-refresh')
            this.$root.$emit('cms::issue-sidebar', { issue, open: true })
          } else {
            this.toast('Erro!', 'Não foi possível criar o chamado', false)
          }
        }).catch(error => {
          const errorMessage = error?.response?.data?.errors[1]

          if (errorMessage) {
            this.toast('Erro!', errorMessage, false)
          } else {
            this.toast('Erro!', 'Não foi possível criar o chamado', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.$emit('prevent-close-sidebar', false)
        })
      })
    },

    clearNewCommentForm() {
      this.content = this.defaultContent
      this.assignTo = this.defaultAssignTo
      this.attachment = this.defaultAttachment
    },

    handleNewComment() {
      this.$refs.rulesNewComment.validate().then(success => {
        if (!success) {
          return
        }

        this.isLoading = true
        this.$emit('prevent-close-sidebar', true)

        /* eslint-disable camelcase */
        const {
          assignTo: to_id,
          status,
          attachment,
          content,
          tags,
        } = this
        const { id } = this.issue

        const formData = new FormData()
        formData.append('content', content)

        tags.forEach(tag => {
          formData.append('tags[]', tag)
        })

        if (Number.isNaN(parseInt(to_id, 10))) {
          formData.append('to_all_from', to_id)
        } else {
          formData.append('to_id', to_id)
        }

        if (attachment) {
          formData.append('attachment', attachment)
        }

        if (status) {
          formData.append('status', status)
        }

        if (id) {
          formData.append('id', id)
        }

        IssueService.store(formData).then(response => {
          if (response.status === 200) {
            const { issue } = response.data.data

            this.clearNewCommentForm()
            this.$root.$emit('cms::issue-refresh')
            this.$root.$emit('cms::issue-sidebar', { issue, open: true })
          } else {
            this.toast('Erro!', 'Não foi possível comentar no chamado', false)
          }
        }).catch(error => {
          const errorMessage = error?.response?.data?.errors[1]

          if (errorMessage) {
            this.toast('Erro!', errorMessage, false)
          } else {
            this.toast('Erro!', 'Não foi possível comentar no chamado', false)
          }
        }).finally(() => {
          this.isLoading = false
          this.$emit('prevent-close-sidebar', false)
        })
      })
    },
  },
}
</script>

<style lang="scss">
.issues-sidebar {
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
}
</style>
